import React from "react"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import Breadcrumb from "../components/Breadcrumb"
import ListMattress from "../components/ListMattress"
import Seo from "../components/Seo/Seo"
import { getVariables } from "../lib/utils"

import { graphql } from "gatsby"

export const query = graphql`
    query ($slug: [String!]) {
        allDatoCmsMattress(
            filter: {
                locale: {eq: "en"},
                location: {
                    websiteUrl: {eq: "us.mattress.zone"}
                },
                mattressTypeFilter: {slug: {in: $slug}}
            },
            sort: {
                fields: name, 
                order: ASC
            }
            ) {
            nodes {
                name
                slug
                location {
                    websiteUrl
                }
                mattressImage {
                    gatsbyImageData(
                        imgixParams: {auto: "format", fit: "crop", h: "125", w: "250", colors: 10, cornerRadius: "10,10,10,10", mask: "corners"}
                        placeholder: BLURRED
                    )
                }
                id
                brand {
                    slug
                }
                mattressType {
                    slug
                }
            }
        }
    }`

const MattressType = ({ location, data }) => {
    const variables = getVariables()
    let pageTitle = "Mattress Type"
    let pathname = location.pathname.split("/")
    if (pathname != null) {
        //pageTitle = pathname[pathname.length - 1].replaceAll("-", " ").toUpperCase() //default value
        pageTitle = pathname[pathname.length - 1].replace(/-/g, ' ').toUpperCase()
    }

    let breadcrumbData = [
        { 'link': null, 'label': pageTitle }
    ]

    return (
        <Layout>
            <Seo
                title={null}
                description={null}
                image={null}
                pathname={`/${variables["mattress-type"]}/${pathname[pathname.length - 1]}`}
            />
            <div className="bg-zone-1">
                <main>
                    <div className="container mx-auto">
                        <PageTitle
                            newestDate={null}
                            title={pageTitle}
                            link={null}
                            category={variables.mattress_type} />

                        <Breadcrumb items={breadcrumbData} />
                    </div>
                    <ListMattress container={true} className="bg-zone-3 py-12 px-4 xl:px-0" mattresses={data.allDatoCmsMattress.nodes} />
                </main>
            </div>
        </Layout>
    )
}

export default MattressType

